<template>
  <div class="reasons-visit-area">
    <div class="reasons-visit-area__wrapper">
      <Column>
        <slot name="reasons-visit-area-title" />
        <!--        <Column v-if="$mobile"-->
        <!--                class="reasons-visit-area__white-block-mobile">-->
        <!--          <div class="reasons-visit-area__white-block-mobile-wrapper">-->
        <!--            <div class="reasons-visit-area__white-block-mobile-card"-->
        <!--                 v-for="reason in reasons"-->
        <!--                 :key="reason.id">-->
        <!--<p class="reasons-visit-area__white-block-mobile-title">{{reason.title}}</p>-->
        <!--              <img class="reasons-visit-area__white-block-mobile-image"-->
        <!--                   :src="reason.images[0]"-->
        <!--                   alt>-->
        <!--              <p class="reasons-visit-area__white-block-mobile-description">-->
        <!--                {{reason.description}}</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </Column>-->
        <div v-if="$laptop"
          class="reasons-visit-area__slider">
          <div :style="{transform: `translateX(${slides.left}px)`}"
            class="reasons-visit-area__slider-wrapper">
            <div :class="current !== -index ? 'reasons-visit-area__reason-unactive' : ''"
              :key="reason.id"
              class="reasons-visit-area__reason grid-md-6"
              v-for="(reason, index) in reasons">
              <Row justify="between">
                <div class="reasons-visit-area__image-holder">
                  <img :src="reason.images[0]"
                    alt=""
                    class="reasons-visit-area__reason-image"/>
                </div>
                <Column class="reasons-visit-area__description-holder">
                  <h5 class="reasons-visit-area__reason-title">{{ reason.title }}</h5>
                  <p class="reasons-visit-area__reason-description">
                    {{ $stripHtml(reason.description) }}
                  </p>
                </Column>
              </Row>
            </div>
          </div>
          <div class="reasons-visit-area__slider-button reasons-visit-area__slider-preview"
            v-if="current !== (reasons.length * -1) + 1"
            v-on:click="nextSlide">
            <Icon class="reasons-visit-area__slider-button-svg"
              viewport="0 0 25 18"
              xlink="arrow-slider-next"/>
          </div>
          <div class="reasons-visit-area__slider-button reasons-visit-area__slider-next"
            v-if="current < 0"
            v-on:click="previewSlide">
            <Icon class="reasons-visit-area__slider-button-svg"
              viewport="0 0 25 18"
              xlink="arrow-slider-preview"/>
          </div>
        </div>
        <div v-if="!$laptop"
          class="relative">
          <Row class="reasons-visit-area__scroll-snap">
            <ReasonCard v-for="reason in reasons"
              :key="reason.id"
              :params="{id: reason.id,
                title: reason.title,
                description: reason.description,
                images: reason.images}"/>
            <div :style="$mobile
              ? {width: '12px'}
              : {width: 'calc((100% / 2) - 380px)'}"></div>
          </Row>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReasonsVisitArea',
  props: {
    limiter: {
      type: Boolean,
    },
    indentation: {
      default: 30,
    },
  },
  data() {
    return {
      slide: {
        width: 0,
        height: 0,
      },
      slides: {
        left: 0,
      },
      current: 0,
    };
  },
  computed: {
    reasons() {
      return this.$articles?.data;
    },
  },
  methods: {
    setSlidesPosition() {
      // TODO убрать фиксированный размер
      this.slides.left = (825 + this.indentation) * this.current;
    },
    nextSlide() {
      this.current -= 1;
      this.setSlidesPosition();
    },
    previewSlide() {
      this.current += 1;
      this.setSlidesPosition();
    },
  },
};
</script>
